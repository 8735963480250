import { Component, OnInit } from '@angular/core';
import * as centroid from "@turf/centroid";
import * as L from 'leaflet';
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { ServiceService } from '../../service.service';

@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.css']
})

export class VisorComponent implements OnInit {
  map: L.Map;
  datos1: any;
  datos2: any;
  datos3: any;
  wfsStyle = {
    fillOpacity: 0.5,
    fillColor: 'white',
    color: "white",
    weight: 2,
  };
  markerCluster1: L.LayerGroup = L.layerGroup();
  markerCluster2: L.LayerGroup = L.layerGroup();
  markerCluster3: L.LayerGroup = L.layerGroup();
  myIcon1 = L.icon({
    iconUrl: '../../../assets/logo1.png',
    iconSize: [35, 35],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16]
  });
  myIcon2 = L.icon({
    iconUrl: '../../../assets/logo2.png',
    iconSize: [30, 30],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16]
  });
  myIcon3 = L.icon({
    iconUrl: '../../../assets/logo3.png',
    iconSize: [30, 30],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16]
  });
  departamentos: any;
  departamento: any;
  valor: number;
  datosDepartamento: any;
  sw: number;
  constructor(private homologacionService: ServiceService) { }

  async ngOnInit() {
    this.valor=0;
    const data = await this.homologacionService.listaDepartamento();
    this.departamentos= data;
    this.satelite();
    this.selecionar();
  }

  satelite() {
    this.map = L.map('map').setView([-16.28174,-65.09399], 6);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
    const tileLayers = {
      satellite: L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 18,
          tileSize: 512,
          zoomOffset: -1,
      }),

    };
    const controlCapas = L.control.layers(null, null, { position: 'topleft' }).addTo(this.map);
    controlCapas.addOverlay(tileLayers.satellite, 'Satellite');
    this.map.zoomControl.remove();
  }

  async selecionar(){
    const data = await this.homologacionService.departamento()
      this.datosDepartamento = data[0].row_to_json.features;
      const Layer_Manzanas = L.geoJSON(this.datosDepartamento, {
        style: this.estilo_manzanas,
      });
      this.map.addLayer(Layer_Manzanas);

  }

  async puntos1(){
    this.valor=1;
    this.markerCluster1 = L.layerGroup();
    const data = await this.homologacionService.listar(1,this.departamento)
      this.datos1 = data[0].row_to_json.features;
      const Layer_Edificios = L.geoJSON(this.datos1, {
        onEachFeature: this.onEachFeature1.bind(this),
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng, {icon: this.myIcon1});
        }  
      })
      this.markerCluster1.addLayer(Layer_Edificios);
      this.markerCluster1.addTo(this.map);
      if(this.sw==0){
        const ubicacion= this.datosDepartamento.find(res=>res.properties.cod_depto==this.departamento);
        const limite = centroid.default(ubicacion).geometry.coordinates;
        this.map.flyTo([limite[1],limite[0]],8);
      }
      
  }

  async puntos2(){
    this.valor=3;
    this.markerCluster2 = L.layerGroup();
    const data =  await this.homologacionService.listar(3,this.departamento)
      this.datos2 = data[0].row_to_json.features;
      const Layer_Edificios = L.geoJSON(this.datos2, {
        onEachFeature: this.onEachFeature2.bind(this),
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng, {icon: this.myIcon2});
        }  
      })
      this.markerCluster2.addLayer(Layer_Edificios);
      this.markerCluster2.addTo(this.map);
      if(this.sw==0){
        const ubicacion= this.datosDepartamento.find(res=>res.properties.cod_depto==this.departamento);
        const limite = centroid.default(ubicacion).geometry.coordinates;
        this.map.flyTo([limite[1],limite[0]],8);
      }
  }

  async puntos3(){
    this.valor=4;
    this.markerCluster3 = L.layerGroup();
    const data = await this.homologacionService.listar(4,this.departamento)
      this.datos3 = data[0].row_to_json.features;
      const Layer_Edificios = L.geoJSON(this.datos3, {
        onEachFeature: this.onEachFeature3.bind(this),
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng, {icon: this.myIcon3});
        }  
      })
      this.markerCluster3.addLayer(Layer_Edificios);
      this.markerCluster3.addTo(this.map);
      if(this.sw==0){
        const ubicacion= this.datosDepartamento.find(res=>res.properties.cod_depto==this.departamento);
        const limite = centroid.default(ubicacion).geometry.coordinates;
        this.map.flyTo([limite[1],limite[0]],8);
      }
  }

  private onEachFeature1(feature, layer): void {
    const popupContent = `<h5 style="background: #2e865b52; padding: 2%">OFICINA CENSAL</h5><hr/>
    <label><b>ESTABLECIMIENTO: </b><br>${feature.properties.nombre_establecimiento}</label><br>
    <label><b>DIRECCIÓN: </b><br>${feature.properties.direccion}</label><br>
    <label><b>JEFE DE AREA/ZONA: </b><br>${feature.properties.nombre}</label><br>
    <label><b>CELULAR: </b><br>${feature.properties.celular}</label>`;
    layer.bindPopup(popupContent);
  }

  private onEachFeature2(feature, layer): void {
    const popupContent = `<h5 style="background: #2e865b52; padding: 2%">CENTRO DE OPERACIONES</h5><hr/>
    <label><b>ESTABLECIMIENTO: </b><br>${feature.properties.nombre_establecimiento}</label><br>
    <label><b>DIRECCIÓN: </b><br>${feature.properties.direccion}</label><br>
    <label><b>JEFE UTC: </b><br>${feature.properties.nombre}</label><br>
    <label><b>CELULAR: </b><br>${feature.properties.celular}</label>`;
    layer.bindPopup(popupContent);
  }

  private onEachFeature3(feature, layer): void {
    const popupContent = `<h5 style="background: #26075531; padding: 2%">AMBIENTES DE CAPACITACIÓN</h5><hr/>
    <label><b>NOMBRE</b><br>${feature.properties.nombre_establecimiento}</label><br>
    <label><b>DIRECCIÓN</b><br>${feature.properties.direccion}</label>`;
    layer.bindPopup(popupContent);
  }

  estilo_manzanas(feature) {
      return {
        weight: 2.5,
        color: '#071A0D',
        fillcolor: '#071A0D',
        opacity: 1.0,
        fillOpacity: 0.05
      }
  }
  
  opcion(id: number){
    this.sw=1;
    this.valor=id;
    this.map.removeLayer(this.markerCluster1);
    this.map.removeLayer(this.markerCluster2);
    this.map.removeLayer(this.markerCluster3);
    if(id==1){
      this.puntos1();
    }
    if(id==3){
      this.puntos2();
    }
    if(id==4){
      this.puntos3();
    }
  }

  actualizar(){
    this.sw=0;
    this.valor=1;
    this.map.removeLayer(this.markerCluster1);
    this.map.removeLayer(this.markerCluster2);
    this.map.removeLayer(this.markerCluster3);
    if(this.valor==1){
      this.puntos1();
    }
    if(this.valor==3){
      this.puntos2();
    }
    if(this.valor==4){
      this.puntos3();
    }
  }

}