import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  })

  departamento() {
    return fetch(`https://ehrest2.ine.gob.bo/creacion/departamento`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  listaDepartamento() {
    return fetch(`https://ehrest2.ine.gob.bo/creacion/listaDepartamento`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  listar(id: number, depto: string) {
    return fetch(`https://ehrest2.ine.gob.bo/creacion/listar/${id}/${depto}`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }


  /*departamento() {
    return this.http.get(`https://ehrest2.ine.gob.bo/creacion/departamento`);
  }

  listaDepartamento() {
    return this.http.get(`https://ehrest2.ine.gob.bo/creacion/listaDepartamento`);
  }

  listar(id: number, depto: string){
    return this.http.get(`https://ehrest2.ine.gob.bo/creacion/listar/${id}/${depto}`);
  }*/
}
