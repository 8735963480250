

<div class="container-fluid" style="padding: 0.5%;">
    <div class="row">
        <div class="col-md-12">
            <div id="map" class="p-relative" style="height: 96vh;">
                <div class="divContainer">
                    <ng-select [items]="departamentos" bindLabel="depto" bindValue="cod_depto" placeholder="Seleccione el Departamento" [(ngModel)]="departamento" (change)="actualizar()">
                    </ng-select><hr/>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (click)="opcion(1)" [disabled]="departamento==null" [checked]="valor==1">
                        <label class="form-check-label" for="flexRadioDefault1"> OFICINA CENSAL </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="opcion(3)" [disabled]="departamento==null" [checked]="valor==3">
                        <label class="form-check-label" for="flexRadioDefault2"> CENTRO DE OPERACIONES </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" (click)="opcion(4)" [disabled]="departamento==null" [checked]="valor==4">
                        <label class="form-check-label" for="flexRadioDefault3"> AMBIENTES DE CAPACITACIÓN </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
